<template>
  
  <div class="home container-fluid">
  <mdb-row>
      <mdb-col sm="12" md="12" lg="12" class="d-flex justify-content-between align-items-center" >
       <div> <h3 style="font-weight:500;">{{hourZone}} {{$store.state.auth.user.firstname}}</h3>
        <h5>{{$store.state.auth.site.name}}</h5> </div>
      
        <div class="" v-show="$gate.checkTypeRole(['super-admin','admin'])">
          <mdb-simple-chart :value="getTotalStorageUsed" :color="getcolorStorageRecap">
           <div style="line-height:.9rem; text-align:center" > <b>{{getTotalStorageUsed}}%</b>
            <div style="font-size:.7rem" >Stockage</div>
            <div style="font-size:.7rem" >Utilisé</div></div>
          </mdb-simple-chart>
          <mdb-badge v-if="false" class="px-2" color="primary"
            >Stockage Utilisé
         </mdb-badge >
        </div>
      </mdb-col >
       <mdb-col v-if="$gate.checkTypeRole(['super-admin','admin'])" sm="12" md="12" lg="12" class="mt-3 mb-3 align-items-center d-flex justify-content-between">
       <h5 style="font-weight:500">Aperçu du compte</h5>
          <el-dropdown trigger="click">
            <el-button type="primary"  class="px-2 py-2">
              {{option}} <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="starterR('Cette Semaine')">Cette Semaine</el-dropdown-item>
              <el-dropdown-item @click.native="starterR('Ce Mois')">Ce mois</el-dropdown-item>
              <el-dropdown-item @click.native="starterR('3 Mois')">3 mois</el-dropdown-item>
              <el-dropdown-item @click.native="starterR('6 Mois')">6 mois</el-dropdown-item>
              <el-dropdown-item @click.native="starterR('Cette Année')">Cette année</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </mdb-col>
  </mdb-row>
    
    <mdb-row v-if="$gate.checkTypeRole(['super-admin','admin'])" >
      <mdb-col class="col-sm-12">
        <revenues :revenueProp="revenues" :option="option"></revenues>
      </mdb-col>
      
      <mdb-col sm="12" md="12" lg="6" class="mb-4">
        <mdb-card cascade :narrow="($store.state.theme === 'theme-light')? true : false">
          <mdb-view cascade class="card-header-bg gradient-card-header  text-primary color-primary-reversed light-teps  card-header-reversed"
            ><h6 class="mb-0 " style="font-weight: 500;">Evolution du chiffre d'affaire des factures de vente et des factures d'avoir</h6></mdb-view
          >
          <mdb-card-body class="">
              <revenues-fv-fa :render="chartRender"  :lineChartDataProp="lineChartDataFvFa" ></revenues-fv-fa>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="12" md="12" lg="6" class="mb-4">
        <mdb-card cascade :narrow="($store.state.theme === 'theme-light')? true : false">
          <mdb-view cascade class="card-header-bg gradient-card-header text-primary color-primary-reversed light-teps card-header-reversed"
            ><h6 class="mb-0 " style="font-weight: 500;">Evolution du chiffre d'affaire des factures de vente et des factures avoir à l'exportation</h6></mdb-view
          >
          <mdb-card-body class="card-reversed">
              <revenues-ev-ea :render="chartRender"  :lineChartDataProp="lineChartDataEvEa"></revenues-ev-ea>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
      <mdb-col sm="12" md="12" lg="6" v-if="$gate.checkTypeRole(['super-admin','admin'])">
        <sites></sites>
      </mdb-col>
      <mdb-col sm="12" md="12" lg="6" v-if="$gate.checkTypeRole(['super-admin','admin'])">
        <users></users>
      </mdb-col>
    </mdb-row>

    <section v-if="$isMobile() && ($gate.checkTypeRole(['user']) && $gate.hasPermission(['view-any::invoice']))" class="mb-4 mt-4" >
      <mdb-row>
        <mdb-col col="6" class="mb-2"  @click.native="$router.push({path:'/factures'})">
          <mdb-card @click.native="$router.push({path:'/factures'})">
            <mdb-row>
              <mdb-col  col="12" class="text-center px-0 py-3" >
                <div style="" @click.native="$router.push({path:'/factures'})"> 
                  <div>
                    <i class="fas fa-file-alt"></i>
                  </div>
                  <h6 class="font-weight-bold"> Factures </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="6" v-if="$store.state.auth.user.role === 'user'  && $gate.hasPermission(['view-any::customer'])" class="mb-2" @click.native="$router.push({path:'/utilisateurs/clients'})">
          <mdb-card @click.native="$router.push({path:'/utilisateurs/clients'})">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3" >
                <div style="" @click.native="$router.push({path:'/utilisateurs/clients'})"> 
                  <div>
                    <i class="fas fa-users"></i>
                  </div>
                  <h6 class="font-weight-bold"> Clients </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col v-if="$store.state.auth.user.role !== 'user'   && $gate.hasPermission(['view-any::customer'])" col="6" class="mb-2" @click.native="$router.push({path:'/clients'})">
          <mdb-card @click.native="$router.push({path:'/clients'})">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3" >
                <div style="" @click.native="$router.push({path:'/clients'})"> 
                  <div>
                    <i class="fas fa-users"></i>
                  </div>
                  <h6 class="font-weight-bold"> Clients </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="6" v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user']) && $store.state.auth.company.setting.debt_actived === 1   && $gate.hasPermission(['view-any::credit-sale'])" class="mb-2"  @click.native="$router.push({path:'/ventes-credit'})">
          <mdb-card @click.native="$router.push({path:'/ventes-credit'})">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3 " >
                <div style="" @click.native="$router.push({path:'/ventes-credit'})"> 
                  <div>
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                  <h6 class="font-weight-bold"> Vente à credit </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="6" v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user']) && $store.state.auth.company.setting.debt_actived === 1   && $gate.hasPermission(['view-any::debt-repayment'])" class="mb-2" @click.native="$router.push({path:'/remboursements'})">
          <mdb-card @click.native="$router.push({path:'/remboursements'})">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3 " >
                <div style="" @click.native="$router.push({path:'/remboursements'})"> 
                  <div>
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                  <h6 class="font-weight-bold"> Remboursements </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
    <section v-if="!$isMobile() && $gate.checkTypeRole(['user'])" class="mb-4 mt-4" >
      <mdb-row>
        <mdb-col col="3" class="mb-2"  @click.native="$router.push({path:'/factures'})" v-if="$gate.hasPermission(['view-any::invoice'])">
          <mdb-card @click.native="$router.push({path:'/factures'})" style="cursor:pointer">
            <mdb-row>
              <mdb-col  col="12" class="text-center px-0 py-3" >
                <div style="" @click.native="$router.push({path:'/factures/creer'})"> 
                  <div>
                    <i class="fas fa-file-alt"></i>
                  </div>
                  <h6 class="font-weight-bold"> Factures </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="3" class="mb-2" v-if="$store.state.auth.user.role === 'user' && $gate.hasPermission(['view-any::customer'])" @click.native="$router.push({path:'/utilisateurs/clients'})">
          <mdb-card @click.native="$router.push({path:'/utilisateurs/clients'})" style="cursor:pointer">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3" >
                <div style=""  @click.native="$router.push({path:'/utilisateurs/clients'})"> 
                  <div>
                    <i class="fas fa-users"></i>
                  </div>
                  <h6 class="font-weight-bold"> Clients</h6>
                </div> 
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="3" class="mb-2" v-if="$store.state.auth.user.role !== 'user' && $gate.hasPermission(['view-any::customer'])" @click.native="$router.push({path:'/clients'})">
          <mdb-card @click.native="$router.push({path:'/clients'})" style="cursor:pointer">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3" >
                <div style=""  @click.native="$router.push({path:'/clients'})"> 
                  <div>
                    <i class="fas fa-users"></i>
                  </div>
                  <h6 class="font-weight-bold"> Clients</h6>
                </div> 
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="3" v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user']) && $store.state.auth.company.setting.debt_actived === 1 && $gate.hasPermission(['view-any::credit-sale'])" class="mb-2"  @click.native="$router.push({path:'/ventes-credit'})">
          <mdb-card @click.native="$router.push({path:'/ventes-credit'})" style="cursor:pointer">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3 " >
                <div style="" @click.native="$router.push({path:'/ventes-credit'})"> 
                  <div>
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                  <h6 class="font-weight-bold"> Vente à credit </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
        <mdb-col col="3" v-if="$gate.checkTypeRole(['super-admin', 'admin', 'user']) && $store.state.auth.company.setting.debt_actived === 1 && $gate.hasPermission(['view-any::debt-repayment'])" class="mb-2" @click.native="$router.push({path:'/remboursements'})">
          <mdb-card @click.native="$router.push({path:'/remboursements'})" style="cursor:pointer">
            <mdb-row>
              <mdb-col col="12" class="text-center px-0 py-3 " >
                <div style="" @click.native="$router.push({path:'/remboursements'})"> 
                  <div>
                    <i class="fas fa-dollar-sign"></i>
                  </div>
                  <h6 class="font-weight-bold"> Remboursements </h6>
                </div>
              </mdb-col>
            </mdb-row>
          </mdb-card>
        </mdb-col>
      </mdb-row>
    </section>
  </div>
</template>

<script>
import globalConfig from '@/tstba.conf'
import Sites from "@/tba/views/homes/modules/SiteReports"
import Users from "@/tba/views/homes/modules/UserReports"

import Revenues from "@/tba/views/homes/modules/RevenuesReports"
import RevenuesFvFa from "@/tba/views/homes/modules/RevenuesFvFaByMonth"
import RevenuesEvEa from "@/tba/views/homes/modules/RevenuesEvEaByMonth"
import {
	mdbRow,
	mdbCol,
	mdbCard,
	mdbCardBody,
  mdbView,
  mdbBadge,
  mdbSimpleChart
} from "mdbvue";
export default {
  name: 'home',
  metaInfo: {
      title: 'TECBILL APP',
      titleTemplate: '%s - TABLEAU DE BORD',
      htmlAttrs: {
        lang: 'fr',
        amp: true
      }
    },
  components: {
    Revenues,
    RevenuesFvFa,
    RevenuesEvEa,
    Sites,
    Users,


    mdbRow,
    mdbCol,
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBadge,
    mdbSimpleChart

  },
  data(){
    return{
      revenues:[],
      storage:[],
      option:'Cette Semaine',
      lineChartDataEvEa: {
        labels: [],
        datasets: [
        {
          label: "Facture de vente à l'exportation",
          backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.ev.bgcolor : globalConfig.theme_interface.chart.dark.ev.bgcolor,
          borderColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.ev.borderColor : globalConfig.theme_interface.chart.dark.ev.borderColor,
          pointBackgroundColor: "transparent",
          borderWidth: 2,
          data: []
        },
        {
          label: "Facture d'avoir à l'exportation",
          backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.ea.bgcolor : globalConfig.theme_interface.chart.dark.ea.bgcolor,
          borderColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.ea.borderColor : globalConfig.theme_interface.chart.dark.ea.borderColor,
          pointBackgroundColor: "transparent",
          borderWidth: 2,
          data: []
        }
        ]
      },
      lineChartDataFvFa: {
        labels: [],
        datasets: [
        {
          label: "Facture de vente",
          backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fv.bgcolor : globalConfig.theme_interface.chart.dark.fv.bgcolor,
          borderColor:(this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fv.borderColor : globalConfig.theme_interface.chart.dark.fv.borderColor,
          pointBackgroundColor: "transparent",
          borderWidth: 2,
          data: []
        },
        {
          label: "Facture d'avoir",
          backgroundColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fa.bgcolor : globalConfig.theme_interface.chart.dark.fa.bgcolor,
          borderColor: (this.$store.state.theme === 'theme-light') ? globalConfig.theme_interface.chart.light.fa.borderColor : globalConfig.theme_interface.chart.dark.fa.borderColor,
          pointBackgroundColor: "transparent",
          borderWidth: 2,
          data: []
        }
        ]
      },
      chartRender:false
    }
  },
  methods:{
    async starterR(opt){
      this.option = opt
      switch (opt) {
        case 'Ce Mois':
          this.getRevenuesOnMonthChart(1)
          this.getRecapOnMonth(1)
          break;

        case '3 Mois':
          this.getRevenuesOnMonthChart(3)
          this.getRecapOnMonth(3)
          break;

        case '6 Mois':
            this.getRevenuesOnMonthChart(6)
            this.getRecapOnMonth(6)
          break;

        case 'Cette Année':
          this.getRevenuesOnYearChart()
          this.getRecapOnYear()
          break;
        default:
          this.getRevenuesOnWeekChart()
          this.getRecapOnWeek()
          break;
      }
    },
    async getRevenuesOnYearChart(){
      this.$nprogress.start()
      await this.$store.dispatch('report/revenueOnYear')
      .then((response)=>{
        this.lineChartDataEvEa.labels = [];
        this.lineChartDataEvEa.datasets[0].data = []
        this.lineChartDataEvEa.datasets[1].data = []
        //
        this.lineChartDataFvFa.labels = [];
        this.lineChartDataFvFa.datasets[0].data = []
        this.lineChartDataFvFa.datasets[1].data = []

        this.chartRender = false
        this.lineChartDataEvEa.labels = Object.keys(response.data.revenues.ev)
        this.lineChartDataEvEa.datasets[0].data = Object.values(response.data.revenues.ev)
        this.lineChartDataEvEa.datasets[1].data = Object.values(response.data.revenues.ea)
        //
        this.lineChartDataFvFa.labels = Object.keys(response.data.revenues.fv)
        this.lineChartDataFvFa.datasets[0].data = Object.values(response.data.revenues.fv)
        this.lineChartDataFvFa.datasets[1].data = Object.values(response.data.revenues.fa)
        this.$nprogress.done()
        this.$nextTick(()=>{
          this.chartRender = true
        })
      })
      .catch((error)=>{
        this.$nprogress.done()
        this.$notify({
          message: error.response.data.message  || error.response.data.error,
          type: 'danger'
        })
      });
    },
    async getRevenuesOnWeekChart(){
      this.$nprogress.start()
      await this.$store.dispatch('report/revenueOnWeek')
      .then((response)=>{
        this.lineChartDataEvEa.labels = [];
        this.lineChartDataEvEa.datasets[0].data = []
        this.lineChartDataEvEa.datasets[1].data = []
        //
        this.lineChartDataFvFa.labels = [];
        this.lineChartDataFvFa.datasets[0].data = []
        this.lineChartDataFvFa.datasets[1].data = []

        this.chartRender = false
        this.lineChartDataEvEa.labels = Object.keys(response.data.revenues.ev).map(el =>{
          return this.$moment(el).format('D MMM')
        })
        this.lineChartDataEvEa.datasets[0].data = Object.values(response.data.revenues.ev)
        this.lineChartDataEvEa.datasets[1].data = Object.values(response.data.revenues.ea)
        //
        this.lineChartDataFvFa.labels = Object.keys(response.data.revenues.fv).map(el =>{
          return this.$moment(el).format('D MMM')
        })
        this.lineChartDataFvFa.datasets[0].data = Object.values(response.data.revenues.fv)
        this.lineChartDataFvFa.datasets[1].data = Object.values(response.data.revenues.fa)
        this.$nprogress.done()
        this.$nextTick(()=>{
          this.chartRender = true
        })
      })
      .catch((error)=>{
        this.$nprogress.done()
        this.$notify({
          message: error.response.data.message  || error.response.data.error,
          type: 'danger'
        })
      });
    },
    async getRevenuesOnMonthChart(month){
      this.$nprogress.start()
      await this.$store.dispatch('report/revenueOnMonth',{month:month})
      .then((response)=>{
        this.lineChartDataEvEa.labels = [];
        this.lineChartDataEvEa.datasets[0].data = []
        this.lineChartDataEvEa.datasets[1].data = []
        //
        this.lineChartDataFvFa.labels = [];
        this.lineChartDataFvFa.datasets[0].data = []
        this.lineChartDataFvFa.datasets[1].data = []

        this.chartRender = false
        this.lineChartDataEvEa.labels = Object.keys(response.data.revenues.ev).map(el =>{
          return this.$moment(el).format('D MMM')
        })
        this.lineChartDataEvEa.datasets[0].data = Object.values(response.data.revenues.ev)
        this.lineChartDataEvEa.datasets[1].data = Object.values(response.data.revenues.ea)
        //
        this.lineChartDataFvFa.labels = Object.keys(response.data.revenues.fv).map(el =>{
          return this.$moment(el).format('D MMM')
        })
        this.lineChartDataFvFa.datasets[0].data = Object.values(response.data.revenues.fv)
        this.lineChartDataFvFa.datasets[1].data = Object.values(response.data.revenues.fa)
        this.$nprogress.done()
        this.$nextTick(()=>{
          this.chartRender = true
        })
      })
      .catch((error)=>{
        this.$nprogress.done()
        this.$notify({
          message: error.response.data.message  || error.response.data.error,
          type: 'danger'
        })
      });
    },

    async getRecapOnYear(){
        this.$nprogress.start()
        await this.$store.dispatch('report/recapRevenueYear')
        .then((response)=>{
            this.$nprogress.done()
            this.revenues = response.data.revenues;
        })
        .catch((error)=>{
            this.$nprogress.done()
            this.$notify({
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        });
    },
    async getRecapOnWeek(){
        this.$nprogress.start()
        await this.$store.dispatch('report/recapRevenueWeek')
        .then((response)=>{
            this.$nprogress.done()
            this.revenues = response.data.revenues;
        })
        .catch((error)=>{
            this.$nprogress.done()
            this.$notify({
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        });
    },
    async getRecapOnMonth(month){
        this.$nprogress.start()
        await this.$store.dispatch('report/recapRevenueMonth',{month:month})
        .then((response)=>{
            this.$nprogress.done()
            this.revenues = response.data.revenues;
        })
        .catch((error)=>{
            this.$nprogress.done()
            this.$notify({
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        });
    },
    async getStorageRecap(){
        this.$nprogress.start()
        await this.$store.dispatch('report/storageRecapReports')
        .then((response)=>{
            this.$nprogress.done()
            this.storage = response.data.storage;
        })
        .catch((error)=>{
            this.$nprogress.done()
            this.$notify({
                message: error.response.data.message  || error.response.data.error,
                type: 'danger'
            })
        });
    }
  },
  created(){
    if(this.$gate.checkTypeRole(['super-admin','admin'])){
      this.getStorageRecap()
      this.getRevenuesOnWeekChart()
      this.getRecapOnWeek()
    }
  },
  computed: {
    hourZone(){
      let say = "";
      let now = this.$moment().format("HH")
      if(parseInt(now) >= 0 && parseInt(now) <= 17)
        say =  "Bonjour"; 
      else
        say =  "Bonsoir";

      return say;
    },
    getTotalStorage(){
      return this.storage.size_space
    },

    getTotalInvoiceCount(){
      return this.storage.invoice_count
    },

    getTotalStorageUsed(){
      return Math.round((this.getTotalInvoiceCount * 100 ) /this.getTotalStorage)
    },

    getcolorStorageRecap(){
      let result = "#28a745"

      if(this.getTotalStorageUsed <= 50 ){
        result = '#28a745'
      }else if(this.getTotalStorageUsed <= 75){
        result = '#ffc107'

      }else{
        result = '#dc3545'
      }

      return result
    }
  },
}
</script>
